import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi'; import Helmet from '../component/common/Helmet';
import TextLoop from 'react-text-loop';
import HeaderThree from '../component/header/HeaderThree';
import TabTwo from '../elements/tab/TabTwo';
import ContactThree from '../elements/contact/ContactThree';
import BlogContent from '../elements/blog/BlogContent';

const SlideList = [
  {
    textPosition: 'text-left',
    category: 'Welcome to a small part of me',
    description: '',
    buttonText: 'download CV',
    buttonLink: '/assets/docs/carlos_grillet_cv.pdf',
  },
];
const PortfolioLanding = () => {
  let title = 'About Me',
    description = `I can introduce myself as an accomplished Electronic Engineer with 4 years of experience in the telecom area. I’m an expert in the design, implementation, and maintenance of complex network infrastructure, with a strong focus on network security and network automation.
      I have extensive experience working with firewalls such as Fortigate, Cisco PIX, ASA, and Firepower, and is well-versed in Cisco routing and switching equipment, pfSense firewalls, and VMWare virtualization with ESXi. In addition, I have a deep understanding of Linux servers, and I have successfully implemented and managed these systems in multiple environments.
      My impressive technical skills, attention to detail, and commitment to delivering high-quality work, they would be a valuable addition to any team in need of a skilled network engineer.`;
  const PostList = BlogContent.slice(0, 6);
  return (
    <div className="active-dark">
      <Helmet pageTitle="Portfolio Landing" />

      <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
      {/* Start Slider Area   */}
      <div id="home" className="fix">
        <div className="slider-wrapper">
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25"
              key={index}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ''}
                      <h1 className="title">
                        I'm Carlos Grillet
                        <br />
                        <TextLoop>
                          <span> Networking Engineer.</span>
                          <span> Software Engineer.</span>
                          <span> Cloud Practitioner.</span>
                          <span> Python Developer.</span>
                        </TextLoop>{' '}
                      </h1>
                      <h2>based in Poland</h2>
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ''
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn">
                          <a
                            download={true}
                            className="rn-button-style--2 btn-primary-color"
                            href={`${value.buttonLink}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start About Area */}
      <div id="about" className="fix">
        <div className="about-area ptb--120  bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/about/about-1.jpg"
                      alt="Me at the datacenter"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p align="justify" className="description">{description}</p>
                    </div>
                    <div className="row mt--30">
                      <TabTwo tabStyle="tab-style--1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}

      {/* Start Contact Area */}
      <div id="contact" className="fix">
        <div className="rn-contact-area ptb--120 bg_color--1">
          <ContactThree
            //contactImages="/assets/images/about/about-2.png"
            contactTitle="Contact me"
          />
        </div>
      </div>
      {/* End COntact Area */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default PortfolioLanding;
