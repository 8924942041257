import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Main skills",
        tab2 = "Experience",
        tab3 = "Education & Certification",
        tab4 = "Languages";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service"> Networking <span> - Cisco CCNA</span></a>
                                                    Connect the user with the world.
                                                </li>
                                                <li>
                                                    <a href="/service"> Automation <span> - Cisco DevNet</span></a>
                                                    Don't do the same twice.
                                                </li>
                                                <li>
                                                    <a href="/service">Python programing <span> - Development</span></a>
                                                    Programing for every platform.
                                                </li>
                                                <li>
                                                    <a href="/service">Cibersecurity<span> - Specialist</span></a>
                                                    Keep everithing safe. CIS - ISO.
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Technical support specialist<span> - Universidad Yacambu</span></a> Venezuela: 2020 - 2021
                                               </li>
                                               <li>
                                                   <a href="/service">Networking Engineer<span> - Inter</span></a> Venezuela: 2021 - 2023
                                               </li>
                                               <li>
                                                   <a href="/service">Software Consulting Engineer<span> - Cisco </span></a> Poland: 2023 - current
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Electronic Engineer<span> - Universidad Yacambu, VE</span> 2020</a> 
                                               </li>
                                               <li>
                                                   <a href="/service">Cisco CCNA<span> - Cisco Fermin Toro, VE</span> 2021</a> 
                                               </li>
                                               <li>
                                                   <a href="/service">JavaScritp developer<span> - Udemy, online</span> 2023</a> 
                                               </li>
                                               <li>
                                                   <a href="/service">ISO-27001 Information security management<span> - Udemy, online</span> 2023</a> 
                                               </li>
                                               <li>
                                                   <a href="/service">ISO-27001 Information security controls<span> - Udemy, online</span> 2023</a> 
                                               </li>
                                               <li>
                                                   <a href="/service">ISO-31000 Risk management framework<span> - Udemy, online</span> 2023</a> 
                                               </li>
                                               <li>
                                                   <a href="/service">Cisco DevNet<span> - CloudLamb, online</span> 2023</a> 
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">Spanish</a>
                                                    Native language.
                                                </li>
                                                <li>
                                                    <a href="/service">English</a>
                                                    C1 Profesional level.
                                                </li>
                                                <li>
                                                    <a href="/service">Polish</a>
                                                    A1 Very basic level.
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;
