const BlogContent = [
    {
        images: 'keyboard',
        title: 'Epomaker SK61',
        category: 'Keyboard'
    },
    {
        images: 'mouse',
        title: 'Hyperx Pulsefire Surge',
        category: 'Mouse'
    },
    {
        images: 'headset',
        title: 'Astro A50',
        category: 'Headset'
    },
    {
        images: 'microphone',
        title: 'Hyperx Solo Cast',
        category: 'Microphone'
    },
    {
        images: 'camera',
        title: 'Razer Kiyo Pro',
        category: 'Camera'
    },
    {
        images: 'mousepad',
        title: 'Corsair MM600',
        category: 'Mousepad'
    },
]

export default BlogContent;